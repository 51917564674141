import React, { useEffect, useState } from "react";
import {
  InfoCircleOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Typography,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Upload,
  Spin,
  Checkbox
} from "antd";
import * as UserService from "../../../../Services/UserService/UserService";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import swal from "sweetalert";
import {
  FcHome,
  FcIphone,
  FcLock,
  FcDepartment,
  FcFeedback,
  FcPlanner,
  FcDocument,
} from "react-icons/fc";
import { FaUserAlt } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { FcApproval } from "react-icons/fc";

const { Title } = Typography;

const PeronalInfo = ({ getUserInformation }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [editProfile, setEditProfile] = useState(false);
  const user_email = localStorage.getItem("email");
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  // const [data, setData] = useState({});
  const [customerEmail, setCustomerEmail] = useState();
  const [passwordModal, setPasswordModal] = useState();
  const [fileList, setFileList] = useState([]);
  // const [uploading, setUploading] = useState(false);
  const [buildingData, setBuildingData] = useState([]);
  const [phone, setPhone] = useState("");
  const [customerNotes, setCustomerNotes] = useState();
  const [selectedbuilding, setSelectedBuilding] = useState();
  const [monthlyReminder, setMonthlyReminder] = useState();
  const navigate = useNavigate();

  let Google_check = localStorage.getItem("google_login");

  const handleNotes = (e) => {
    let notes = e.target.value;
    setCustomerNotes(notes);
  };

  const handleChangeBuilding = (e) => {
    setSelectedBuilding(e.target.value);
  };

  const onFinish = (e) => {
    e.preventDefault();
    if (e.target.email.value !== userData?.email) {
      swal({
        title: "Are you sure?",
        text: "You will be logged out of your current session!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal(
            "An email with verification link has been sent to your new email address!",
            {
              icon: "success",
            }
          ).then(function () {
            let updatedInfo = {
              id: e.target.id.value,
              first_name: e.target.first_name.value,
              last_name: e.target.last_name.value,
              email: e.target.email.value,
              mobile_no: e.target.mobile_no.value,
              apartment: e.target.apartment.value,
              customer_notes: e.target.customer_notes.value
                ? e.target.customer_notes.value
                : customerNotes
                  ? customerNotes
                  : userData?.customer_notes,
              building_name: e.target.building_name.value,
              customerEmail: customerEmail,
              monthly_reminder: monthlyReminder
            };
            dispatch(UserService.updateCustomerDetails(updatedInfo)).then(
              (res) => {
                getUserInfo();
                getUserInformation();
                localStorage.setItem("building_changed", 1);
                localStorage.setItem(
                  "userBuilding",
                  e.target.building_name.value
                );
                toggleView();
                localStorage.clear();
                navigate("/login");
                window.location.reload();
              }
            );
            handleUpload();
          });
        } else {
          swal("Changes not saved!", {
            icon: "info",
          });
        }
      });
    } else {
      let updatedInfo = {
        id: e.target.id.value,
        first_name: e.target.first_name.value,
        last_name: e.target.last_name.value,
        email: e.target.email.value,
        mobile_no: phone,
        apartment: e.target.apartment.value,
        customer_notes: e.target.customer_notes.value
          ? e.target.customer_notes.value
          : customerNotes
            ? customerNotes
            : userData?.customer_notes,
        building_name: e.target.building_name.value,
        customerEmail: customerEmail,
        monthly_reminder: monthlyReminder === false || monthlyReminder === 'false' ? 0 : 1
      };
      dispatch(UserService.updateCustomerDetails(updatedInfo)).then((res) => {
        getUserInfo();
        getUserInformation();
        localStorage.setItem("building_changed", 1);
        localStorage.setItem("userBuilding", e.target.building_name.value);
        swal({
          title: res.message,
          icon: "success",
        });
        toggleView();
      });
      //localStorage.setItem("email", e.target.email.value);
      handleUpload();
    }
  };

  let qr_code = localStorage.getItem("QRverify");

  useEffect(() => {
    dispatch(UserService.Scanned_value(qr_code)).then((res) => {
      setBuildingData(res?.building);
    });
  }, []);

  const toggleView = () => {
    if (editProfile === false) {
      setEditProfile(true);
    } else {
      setEditProfile(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    setLoader(true);
    setOpen(true);
    dispatch(UserService.getUserProfile(user_email)).then((res) => {
      setUserData(res);
      if (res?.monthly_reminder == 1 || res?.monthly_reminder === '1') {
        setMonthlyReminder(true);
      } else {
        setMonthlyReminder(false);
      }
      setPhone(res?.mobile_no);
      setLoader(false);
      setOpen(false);
      setCustomerEmail(res?.email);
    });
  };

  const EditPasswordModal = () => {
    setPasswordModal(true);
  };

  const handleOkPasswordModal = () => {
    setPasswordModal(false);
  };

  const handleCancelPasswordModal = () => {
    setPasswordModal(false);
    onReset();
  };

  const onFinishForm = (values) => {
    let user_id = userData?.user_id;
    let data = { user_id, ...values };
    dispatch(UserService.resetPassword(data)).then((res) => {
    });
    swal({
      title: "Password Changed Successfully",
      icon: "success",
    });
    setPasswordModal(false);
    onReset();
    window.location.reload();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (file.size <= 2000000) {
        setFileList([...fileList, file]);
        return false;
      } else {
        swal({
          text: "Maximum upload size exceeded",
          icon: "error",
        });
      }
    },
    fileList,
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    let user_id = userData?.id;
    dispatch(UserService.updateProfile(user_id, formData))
      .then((res) => {
      })
      .catch(() => {
        console.error("upload failed.");
      })
      .finally(() => { });
  };

  let key = Math.floor(Math.random() * 1000);

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const onChange = (e) => {
    setMonthlyReminder(e.target.checked);
  };

  return (
    <>
      {loader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <Spin />
        </Backdrop>
      ) : (
        <div className="customCard">
          {editProfile === false ? (
            <Row className="viewProfile">
              <Col xs={24} md={24} className="content-row">
                <FcFeedback />
                <Title level={4}>{userData?.email}</Title>
              </Col>
              <Col xs={24} md={24} className="content-row">
                <FaUserAlt />
                <Title level={4}>
                  {userData?.first_name} {userData?.last_name}
                </Title>
              </Col>
              <Col xs={24} md={24} className="content-row">
                <FcIphone />
                <Title level={4}>{userData?.mobile_no}</Title>
              </Col>
              <Col xs={24} md={24} className="content-row">
                <FcHome />
                <Title level={4}>{userData?.apartment}</Title>
              </Col>
              <Col xs={24} md={24} className="content-row">
                <FcDepartment />
                <Title level={4}>{userData?.building_name}</Title>
              </Col>
              <Col xs={24} md={24} className="content-row">
                <FcPlanner />
                <Title level={4}>Monthly Payment Reminder</Title>
              </Col>
              <Col xs={24} md={24} className="content-row">
                <FcDocument />
                <Title level={4}>{userData?.customer_notes}</Title>
              </Col>
              {Google_check == "true" ? (
                ""
              ) : (
                <Col xs={24} md={24} className="content-row">
                  <FcLock />
                  <Title level={4} style={{ fontWeight: "900" }}>
                    *******
                  </Title>
                </Col>
              )}
              <Col xs={24} md={24} className="editRow">
                <Button onClick={toggleView}>Edit Profile</Button>
              </Col>
            </Row>
          ) : (
            <form onSubmit={onFinish} className="infoForm">
              <div className="formHeader">
                <button type="button" onClick={toggleView}>
                  <ArrowLeftOutlined />
                </button>
                <Title level={4}>Edit Information</Title>
              </div>
              <Row>
                <input type={"hidden"} name="id" defaultValue={userData?.id} />
                <Col xs={24} md={24}>
                  <label className="formlabel">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    defaultValue={
                      userData?.first_name ? userData?.first_name : ""
                    }
                  />
                </Col>
                <Col xs={24} md={24}>
                  <label className="formlabel">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    defaultValue={
                      userData?.last_name ? userData?.last_name : ""
                    }
                  />
                </Col>
                <Col xs={24} md={24}>
                  <label className="formlabel">Email</label>
                  <input
                    type="email"
                    name="email"
                    defaultValue={userData?.email ? userData?.email : ""}
                    readOnly={Google_check === "true"}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <label className="formlabel">Mobile Number</label>
                  <PhoneInput
                    defaultCountry="AE"
                    placeholder="Enter phone number"
                    value={phone}
                    name="mobile_no"
                    required
                    initialValue={
                      userData?.mobile_no ? userData?.mobile_no : ""
                    }
                    onChange={handlePhoneChange}
                    limitMaxLength={10}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <label className="formlabel">Apartment</label>
                  <input
                    type="text"
                    name="apartment"
                    defaultValue={
                      userData?.apartment ? userData?.apartment : ""
                    }
                  />
                </Col>
                <Col xs={24} md={24}>
                  <label className="formlabel">Building Name</label>
                  <select
                    className="form-select customSelect"
                    name="building_name"
                    key={key}
                    defaultValue={
                      userData?.building_name
                        ? userData?.building_name
                        : selectedbuilding
                    }
                    onChange={handleChangeBuilding}
                    value={selectedbuilding}
                  >
                    <option value="">Select building</option>
                    {buildingData?.map((e, key) => {
                      return (
                        <option
                          key={key}
                          value={e.name}
                          id={e.id}
                          className="OptionList"
                        >
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col xs={24} md={24}>
                  <label className="formlabel">Customer Notes</label>
                  <input
                    type="text"
                    name="customer_notes"
                    placeholder="Changes to your address."
                    onChange={handleNotes}
                    value={customerNotes}
                    defaultValue={
                      userData?.customer_notes ? userData?.customer_notes : ""
                    }
                  />
                </Col>
                {Google_check === "true" ? (
                  ""
                ) : (
                  <Col xs={24} md={24}>
                    <div
                      className="passwordWrapper"
                      style={{ position: "relative" }}
                    >
                      <label className="formlabel">Password</label>
                      <input
                        type="text"
                        readOnly
                        name="customer_notes"
                        placeholder="Changes to your address."
                        defaultValue="*******"
                        onClick={EditPasswordModal}
                      />
                      <div className="editPassword">
                        <EditOutlined onClick={EditPasswordModal} />
                      </div>
                    </div>
                  </Col>
                )}
                <Col xs={24} md={24}>
                  <label className="formlabel">Monthly Payment Reminder</label>
                  <div className="ReminderToggleWrapper">
                    <Checkbox onChange={onChange} checked={monthlyReminder !== undefined && monthlyReminder === true ? monthlyReminder : false}>{monthlyReminder === true ? <span>Monthly Payment Reminder <FcApproval /></span> : <span>I would like to receive a monthly email reminder for my upcoming payment due date.</span>}</Checkbox>
                  </div>
                </Col>

                <Col xs={24} md={24}>
                  <label className="formlabel">Profile Picture</label>
                  <Upload
                    {...props}
                    accept="image/png, image/jpeg"
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                  <div className="maxImageInfo">
                    <InfoCircleOutlined />
                    Maximum image size is 5 MB.
                  </div>
                </Col>

                <Col xs={24} md={24}>
                  <div className="buttonSection">
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          )}
        </div>
      )}

      {/* Password Modal */}
      <Modal
        open={passwordModal}
        onOk={handleOkPasswordModal}
        onCancel={handleCancelPasswordModal}
        closable={false}
        footer={null}
        className="editPwdModal"
      >
        <div className="editPasswordForm">
          <Row>
            <Col xs={24} md={24} className="modalHeaderSection">
              <div className="modalHeader">
                <Title level={4}>Edit Password</Title>
                <Button onClick={handleCancelPasswordModal}>X</Button>
              </div>
              <hr />
            </Col>
          </Row>
          <Form
            name="basic"
            onFinish={onFinishForm}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <label className="inputLabel">Current Password</label>
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your current Password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <label className="inputLabel">New Password</label>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <label className="inputLabel">Confirm Password</label>
            <Form.Item
              name="confirm_password"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input className="passwordBox" />
            </Form.Item>
            <Form.Item className="buttonSection">
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default PeronalInfo;
