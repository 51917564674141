import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Card,
  Image,
  Modal,
  Spin,
} from "antd";
import * as UserService from "../../../Services/UserService/UserService";
import Logo from "../../../Assets/Images/LoginLogo.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Url } from "../../../Helper";
import swal from "sweetalert";
import Backdrop from "@mui/material/Backdrop";
const { Title } = Typography;

const TabletLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let qr_token = localStorage.getItem("QRverify");
  const [googleUrl, setGoogleUrl] = useState(null);
  const [registerModal, setRegisterModal] = useState(false);
  const [reverifyModal, setReverifyModal] = useState(false);
  const [unverifiedMessage, setunverifiedMessage] = useState();
  const [reverifymail, setReverifyMail] = useState();
  const [disableButton, setDisablebutton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [googleReminder, setGoogleReminder] = useState(false);
  const [googleEmail, setGoogleEmail] = useState(false);

  const onFinish = (values) => {
    dispatch(UserService.login_user(values))
      .then((res) => {
        if (res?.user_status === 0) {
          swal({
            title: "Your account has been disabled.",
            icon: "error",
            button: "Close",
            closeOnClickOutside: false,
          });
        } else if (res?.user_verify === false) {
          setunverifiedMessage(res?.message);
          setReverifyModal(true);
          setReverifyMail(values?.email);
        } else if (res?.case === 'email changed') {
          swal({
            title: res?.message,
            text: res?.text,
            icon: "error",
            button: "Close",
            closeOnClickOutside: false,
          });
        } else {
          if (qr_token === null || qr_token === undefined) {
            console.log("ttttttt");
            navigate("/?callback=" + res.qr_code);
            localStorage.setItem("user_id", res.user_id);
            localStorage.setItem("token", res.token);
            localStorage.setItem("user_name", res.name);
            localStorage.setItem("email", res.email);
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("passwordEntered", true);
            window.location.reload();
          } else {
            if (res === undefined) {
              swal({
                title: "Invalid credentials!",
                icon: "error",
                button: "Try Again",
                closeOnClickOutside: false,
              });
            } else {
              navigate("/?callback=" + qr_token);
              localStorage.setItem("user_id", res.user_id);
              localStorage.setItem("token", res.token);
              localStorage.setItem("user_name", res.name);
              localStorage.setItem("email", res.email);
              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("passwordEntered", true);
              window.location.reload();
            }
            console.log("Dfgfdgdfgdgdfgdgdfgd");
          }
        }
      })
      .catch((err) => {
        swal({
          title: err.response.data.message,
          icon: "error",
          button: "Try Again",
          closeOnClickOutside: false,
        });
      });
  };

  useEffect(() => {
    fetch(Url + "/api/auth/google", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setGoogleUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const registerUser = () => {
    localStorage.setItem("google_login", "false");
    navigate("/?callback=" + qr_token, {
      state: {
        registerTrue: "true",
      },
    });
  };

  const CloseForm = () => {
    navigate("/?callback=" + qr_token, {
      state: {
        registerTrue: "false",
      },
    });
  };

  const googleLogin = () => {
    localStorage.setItem("google_login", "true");
  };

  const handleOkRegister = () => {
    setRegisterModal(false);
  };

  const handleCancelRegister = () => {
    setRegisterModal(false);
  };

  const OpenRegisterModal = () => {
    setRegisterModal(true);
  };

  const handleCancelReverifyModal = () => {
    setReverifyModal(false);
  };

  const resendVerifyMail = () => {
    setDisablebutton(true);
    setLoader(true);
    setOpen(true);
    dispatch(UserService.reSendMail(reverifymail))
      .then((res) => {
        setLoader(false);
        setOpen(false);
        swal({
          icon: "success",
          title:
            "We have sent you the verification mail again to your registered email. Please verify.",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        console.warn(err);
        setLoader(false);
        setOpen(false);
      });
  };

  const checkGoogleLogin = (e) => {
    dispatch(UserService.check_existing_user(e.target.value))
      .then((response) => {
        if (response?.googlelogin === true) {
          setGoogleReminder(true);
          setGoogleEmail(true);
        } else {
          setGoogleEmail(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleOkGoogleReminder = () => {
    setGoogleReminder(false);
  };

  return (
    <>
      {loader === true ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <Spin />
        </Backdrop>
      ) : (
        ""
      )}
      <div className="tabletLoginWrapper">
        <Container>
          <Card className="loginCard">
            <Row>
              <div className="modalHeader">
                <Button onClick={CloseForm}>X</Button>
              </div>
              <Col xs={24} md={24}>
                <Row>
                  <Col xs={24} md={24}>
                    <div className="logoSection">
                      <Image
                        src={Logo}
                        preview={false}
                        alt="Not Found"
                        width={80}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="LoginFormWrapper">
                  <Form
                    name="basic"
                    style={{
                      maxWidth: 600,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <label className="inputLabel">Email Address</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email Address"
                        onBlur={checkGoogleLogin}
                      />
                    </Form.Item>
                    <label className="inputLabel">Password</label>
                    <Form.Item
                      name="password"
                      className="passwordFormItem"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        className="passwordInput"
                      />
                    </Form.Item>
                    <Row className="forgotPwdSection">
                      <Col xs={24} md={24}>
                        <a href="/forgot-password">Forgot Password?</a>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} md={24} className="buttonSection">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={googleEmail === true}
                          >
                            Login
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="registerSection">
                      <Col xs={24} md={24}>
                        <Title level={4}>OR</Title>
                      </Col>
                      <Col xs={24} md={24}>
                        <div className="LoginbtnWrapper">
                          {googleUrl && (
                            <a
                              href={googleUrl}
                              // className="sclLogBtn fbIcn"
                              onClick={googleLogin}
                            >
                              <button
                                type="button"
                                className="login-with-google-btn"
                              >
                                Continue with Google
                              </button>
                            </a>
                          )}
                        </div>
                      </Col>
                      <Col xs={24} md={24}>
                        <div className="registerText">
                          New User?
                          <p onClick={OpenRegisterModal}>Register Here</p>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
      <Modal
        open={registerModal}
        onOk={handleOkRegister}
        onCancel={handleCancelRegister}
        closable={false}
        footer={null}
        className="privacyModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>User Registeration</Title>
              <Button onClick={handleCancelRegister}>X</Button>
            </div>
          </Col>
        </Row>
        <div className="modalBody">
          <Row className="registerSection">
            <Col xs={24} md={24}>
              <div className="noramlRegisteration">
                <Button onClick={registerUser}>Continue with email</Button>
              </div>
            </Col>
            <Col xs={24} md={24}>
              <Title level={4}>OR</Title>
            </Col>
            <Col xs={24} md={24}>
              <div className="LoginbtnWrapper">
                {googleUrl && (
                  <a href={googleUrl} onClick={googleLogin}>
                    <button type="button" className="login-with-google-btn">
                      Continue with Google
                    </button>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={reverifyModal}
        onOk={handleOkRegister}
        closable={false}
        footer={null}
        className="reverifymodal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Account Not Verified</Title>
            </div>
          </Col>
        </Row>

        <div className="modalBody">
          <div className="errorMessage">
            <Title level={4}>
            Sorry, your account is not yet verified. Please use the link sent to your email to verify and continue using the app
            </Title>{" "}
          </div>
          <Row className="registerSection">
            <Col xs={24} md={24}>
              <div className="noramlRegisteration">
                <Button onClick={handleCancelReverifyModal}>Okay</Button>
                <Button
                  onClick={resendVerifyMail}
                  disabled={disableButton === true}
                >
                  Resend Verify Mail
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={googleReminder}
        onOk={handleOkGoogleReminder}
        closable={false}
        footer={null}
        className="googleReminderModal"
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modalHeader">
              <Title level={4}>Google Login</Title>
              <Button onClick={handleOkGoogleReminder}>X</Button>
            </div>
          </Col>
        </Row>

        <div className="modalBody">
          <Row className="registerSection">
            <p>
              Looks like you have used Google Login process to register yourself
              with us. Please use the same process to login into your account.
            </p>
            <Col xs={24} md={24}>
              <div className="LoginbtnWrapper">
                {googleUrl && (
                  <a
                    href={googleUrl}
                    // className="sclLogBtn fbIcn"
                    onClick={googleLogin}
                  >
                    <button type="button" className="login-with-google-btn">
                      Continue with Google
                    </button>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default TabletLogin;
